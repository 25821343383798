import { useState, useEffect } from "react";
import songsJson from "./songs.json";
import logo from "./images/logo.png";

function App() {
  const PAGE_SIZE = 5;
  const [pages, setPages] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [checkedSongs, setCheckedSongs] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState(songsJson);
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [details, setDetails] = useState("");
  const [confirm, setConfirm] = useState(false);
  const [state, setState] = useState(false);
  const [langue, setLangue] = useState("");


  // DATA FILTER
  // const filterData = (data, query, checkedSongs = [], langue = '') => {
  //   const filteredData = data.filter((item) => {
  //     return !checkedSongs.some((checkedSong) => checkedSong.id === item.id);
  //   });

  //   if (!query && !langue) {
  //     return filteredData;
  //   }

  //   const searchedData = filteredData.filter((item) => {
  //     const titreMatch = item.titre.toLowerCase().includes(query.toLowerCase());
  //     const chanteurMatch = item.chanteur.toLowerCase().includes(query.toLowerCase());
  //     const langueMatch = item.langue.toLowerCase() === langue.toLowerCase();
  //     return titreMatch || chanteurMatch || langueMatch;
  //   });

  //   return searchedData;
  // };

const filteredSongs = songsJson.filter(
  (song) =>
    (!searchQuery ||
      song.titre.toLowerCase().includes(searchQuery.toLowerCase()) ||
      song.chanteur.toLowerCase().includes(searchQuery.toLowerCase())) &&
    !checkedSongs.some((s) => s.id === song.id) &&
    (langue === "" || song.langue.toLowerCase() === langue.toLowerCase())
);

  // SEARCH
  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    if (query) {
      setCurrentPage(1);
      // setFilteredData(filterData(songsJson, query));
    } else {
      // setFilteredData(songsJson);
    }
  };

  const handleClearSearch = () => {
    setSearchQuery("");
    setFilteredData(songsJson);
  };

  // PAGINATION
  const startIndex = (currentPage - 1) * PAGE_SIZE;
  const endIndex = startIndex + PAGE_SIZE;

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const calculatePageCount = (itemCount, pageSize) => {
    return Math.ceil(itemCount / pageSize);
  };

  useEffect(() => {
    setPages(calculatePageCount(filteredData.length, PAGE_SIZE));
  }, []);

  const paginatedSongs = filteredSongs.slice(startIndex, endIndex);

  // LOCAL STORAGE
  const getInputFromlocal = () => {
    const checkedSongsFromLocalStorage = JSON.parse(
      localStorage.getItem("checkedSongs")
    );
    if (checkedSongsFromLocalStorage) {
      setCheckedSongs(checkedSongsFromLocalStorage);
    }
  };

  useEffect(() => {
    getInputFromlocal();
  }, []);

  useEffect(() => {
    localStorage.setItem("checkedSongs", JSON.stringify(checkedSongs));
  }, [checkedSongs]);

  // SELECT/REMOVE SONGS
  function handleRemoveSong(songId) {
    setCheckedSongs((prevCheckedSongs) =>
      prevCheckedSongs.filter((song) => song.id !== songId)
    );
  }

  function handleSongClick(id) {
    const song = songsJson.find((song) => song.id === id);
    if (checkedSongs.length === 1) {
      window.alert("Vous ne pouvez sélectionner qu'une chanson");
    } else if (!checkedSongs.some((s) => s.id === id)) {
      setCheckedSongs((prevCheckedSongs) => [...prevCheckedSongs, song]);
    }
  }

  // OTHER
  const toggleConfirm = () => {
    setConfirm(!confirm);
  };

  // PHP
  const handlePHP = async (e) => {
    e.preventDefault();
    const data = {
      lastName,
      firstName,
      details,
      songs: checkedSongs,
    };

    try {
      const response = await fetch("./send.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        mode: "cors",
        body: JSON.stringify(data),
      });

      if (response.ok) {
        const responseText = await response.text();
        console.log("Response from PHP:", responseText);
        window.alert("La liste a bien été envoyée !");
        localStorage.clear();
        setCheckedSongs([]);
        setTimeout(() => {
          window.location.href =
            "https://karaoke.camping-balcondechartreuse.com/";
        }, 1000);
      } else {
        console.error("Error sending data to PHP.");
      }
    } catch (error) {
      console.error("An error occurred.", error);
    }
  };

  return (
    <div>
      <header className="bg-[#366036b3] min-h-[100px] py-4 sticky top-0 z-10">
        <img src={logo} alt="logo" className="h-16 m-auto" />
      </header>
      {!confirm && (
        <div>
          <h1 className="text-2xl flex justify-center pt-4">Karaoké</h1>
          <p className="w-full md:w-1/2 m-auto text-center mt-4">
            Veuillez cliquer sur la chanson souhaitée pour la sélectionner. Pour
            supprimer la chanson choisie, cliquez sur la croix pour la
            désélectionner dans "Chanson sélectionnée".
          </p>
          <div></div>
          <div className="w-full md:w-1/2 m-auto flex flex-col items-center">
            <form
              onSubmit={(e) => e.preventDefault()}
              className="max-w-md px-4 mx-auto mt-12"
            >
              <div className="relative">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className={`absolute top-0 bottom-0 w-6 h-6 my-auto text-gray-400 left-3 ${
                    searchQuery ? "cursor-pointer" : ""
                  }`}
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  onClick={handleClearSearch}
                >
                  {!searchQuery && (
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                    />
                  )}
                  {searchQuery && (
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  )}
                </svg>
                <input
                  type="text"
                  placeholder="Rechercher une chanson"
                  className="w-full py-3 pl-12 pr-4 text-gray-500 border rounded-md outline-none bg-gray-50 focus:bg-white focus:border-indigo-600"
                  value={searchQuery}
                  onChange={handleSearch}
                />
              </div>
            </form>

            {/* // Make a dropdown menu to filter by langue */}
            <div className="relative mt-4">
              <select
                className="block appearance-none w-full bg-gray-50 border border-gray-400 hover:border-gray-500 px-4 py-3 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                value={langue}
                onChange={(e) => setLangue(e.target.value)}
              >
                  <option value="">Toutes les langues</option>
                  <option value="FRA">Français</option>
                  <option value="ANG">Anglais</option>
                  <option value="ESP">Espagnol</option>
                  <option value="ITA">Italien</option>
                  <option value="ALL">Allemand</option>
                  <option value="NEE">Néerlandais</option>
                </select>
            </div>



            {/* // SUMMARY */}
            {/* <div className="p-8 w-fit m-auto">
              <details>
                <summary className="text-center">
                  Chansons sélectionnées
                </summary>
                <ul>
                  {checkedSongs.map((song) => (
                    <li key={`checked${song.id}`} className="flex">
                      <button onClick={() => handleRemoveSong(song.id)}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-6 h-6 my-auto text-gray-400 cursor-pointer"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M6 18L18 6M6 6l12 12"
                          ></path>
                        </svg>
                      </button>
                      <strong>{song.chanteur}</strong> - {song.titre}
                    </li>
                  ))}
                </ul>
              </details>
            </div> */}

            {/* // SONGS LIST */}
            <button
              className={`bg-[#599959] hover:bg-green-700 text-white font-bold w-fit py-2 px-4 rounded mt-4 md:mt-0 md:fixed top-[150px] right-[10px] ${
                checkedSongs.length === 0 ? "opacity-50" : ""
              }`}
              onClick={() => setState(true)}
              disabled={checkedSongs.length === 0}
            >
              Chanson sélectionnée
            </button>
            <button
              className={`bg-red-500 hover:bg-red-700 text-white font-bold w-fit py-2 px-4 rounded mt-4 md:mt-0 md:fixed top-[200px] right-[10px] ${
                checkedSongs.length === 0 ? "opacity-50" : ""
              }`}
              onClick={() => setCheckedSongs([])}
              disabled={checkedSongs.length === 0}
            >
             Réinitialiser ma recherche
            </button>
            {state ? (
              <div className="fixed inset-0 z-10 overflow-y-auto">
                <div
                  className="fixed inset-0 w-full h-full bg-black opacity-40"
                  onClick={() => setState(false)}
                ></div>
                <div className="flex items-center min-h-screen px-4 py-8">
                  <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                    <div className="py-3 sm:flex">
                      <div className="flex items-center justify-center flex-none w-12 h-12 mx-auto bg-green-100 rounded-full">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6 text-green-600"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M4.5 12.75l6 6 9-13.5"
                          />
                        </svg>
                      </div>
                      <div className="mt-2 text-center sm:ml-4 sm:text-left">
                        <h4 className="text-lg font-medium text-gray-800 mb-2">
                          Chanson sélectionnée
                        </h4>
                        <ul className="mb-2 text-[11px]">
                          {checkedSongs.map((song) => (
                            <li
                              key={`checked${song.id}`}
                              className="flex min-w-[280px] items-center text-left"
                            >
                              <button onClick={() => handleRemoveSong(song.id)}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="w-6 h-6 my-auto text-gray-400 cursor-pointer"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M6 18L18 6M6 6l12 12"
                                  ></path>
                                </svg>
                              </button>
                              <strong className="hidden md:block">
                                {song.chanteur}
                              </strong>
                              <strong className="truncate max-w-[150px] block md:hidden">
                                {song.chanteur}
                              </strong>
                              <span className="hidden md:block">
                                {" "}
                                - {song.titre}
                              </span>
                              <span className="truncate max-w-[70px] block md:hidden">
                                {" "}
                                - {song.titre}
                              </span>
                            </li>
                          ))}
                        </ul>
                        <div className="items-center gap-2 mt-3 flex justify-center sm:justify-normal">
                          <button
                            className="bg-red-500 hover:bg-red-700 text-white font-bold w-fit py-2 px-4 rounded"
                            onClick={() => setState(false)}
                          >
                            Fermer
                          </button>
                          <button
                            className={`bg-[#599959] hover:bg-green-700 text-white font-bold w-fit py-2 px-4 rounded${
                              checkedSongs.length === 0
                                ? " opacity-50 cursor-not-allowed"
                                : ""
                            }`}
                            onClick={toggleConfirm}
                            disabled={checkedSongs.length === 0}
                          >
                            Confirmer
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            <div className="p-8 w-full">
              {paginatedSongs.length === 0 ? (
                <p className="text-center">
                  Aucune chanson ne correspond à votre recherche
                </p>
              ) : (
                <ul className="divide-y divide-gray-200 songs-list-ul">
                  {paginatedSongs.map((song) => (
                    <li
                      onClick={() => handleSongClick(song.id)}
                      key={song.id}
                      className={`flex py-4 ${
                        checkedSongs.includes(song.id) ? "bg-green-200" : ""
                      } cursor-pointer hover:border-gray-200 hover:bg-green-50`}
                      data-id={song.id}
                    >
                      <div className="ml-3">
                        <p className="text-sm font-medium text-gray-900">
                          {song.chanteur}
                        </p>
                        <p className="text-sm text-gray-500">{song.titre}</p>
                        <p className="text-sm text-gray-500">
                          {song.langue === "FRA"
                            ? "Français"
                            : song.langue === "ALL"
                            ? "Allemand"
                            : song.langue === "ANG"
                            ? "Anglais"
                            : song.langue === "ITA"
                            ? "Italien"
                            : song.langue === "ESP"
                            ? "Espagnol"
                            : song.langue === "NEE"
                            ? "Néerlandais"
                            : song.langue}
                        </p>
                      </div>
                    </li>
                  ))}
                </ul>
              )}
            </div>

            <div className="max-w-screen-xl w-full mx-auto mt-12 px-4 text-gray-600 md:px-8">
              <div
                className="hidden items-center justify-between sm:flex"
                aria-label="Pagination"
              >
                <p
                  className="hover:text-indigo-600 flex items-center gap-x-2 cursor-pointer"
                  onClick={handlePreviousPage}
                  disabled={currentPage === 1}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className="w-5 h-5"
                  >
                    <path
                      fillRule="evenodd"
                      d="M18 10a.75.75 0 01-.75.75H4.66l2.1 1.95a.75.75 0 11-1.02 1.1l-3.5-3.25a.75.75 0 010-1.1l3.5-3.25a.75.75 0 111.02 1.1l-2.1 1.95h12.59A.75.75 0 0118 10z"
                      clipRule="evenodd"
                    />
                  </svg>
                  Précédent
                </p>
                {currentPage} / {pages}
                <p
                  className="hover:text-indigo-600 flex items-center gap-x-2 cursor-pointer"
                  onClick={handleNextPage}
                >
                  Suivant
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className="w-5 h-5"
                  >
                    <path
                      fillRule="evenodd"
                      d="M2 10a.75.75 0 01.75-.75h12.59l-2.1-1.95a.75.75 0 111.02-1.1l3.5 3.25a.75.75 0 010 1.1l-3.5 3.25a.75.75 0 11-1.02-1.1l2.1-1.95H2.75A.75.75 0 012 10z"
                      clipRule="evenodd"
                    />
                  </svg>
                </p>
              </div>
              {/* On mobile version */}
              <div className="flex items-center justify-between text-sm text-gray-600 font-medium sm:hidden">
                <p
                  className="px-4 py-2 border rounded-lg duration-150 hover:bg-gray-50"
                  onClick={handlePreviousPage}
                  disabled={currentPage === 1}
                >
                  Previous
                </p>
                <div className="font-medium">
                  {currentPage} / {pages}
                </div>
                <p
                  className="px-4 py-2 border rounded-lg duration-150 hover:bg-gray-50"
                  onClick={handleNextPage}
                >
                  Next
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-col my-8 gap-2 m-auto items-center sticky bottom-0 py-8 bg-white w-full">
            <button
              className={`bg-[#599959] hover:bg-green-700 text-white font-bold w-fit py-2 px-4 rounded${
                checkedSongs.length === 0
                  ? " opacity-50 cursor-not-allowed"
                  : ""
              }`}
              onClick={toggleConfirm}
              disabled={checkedSongs.length === 0}
            >
              Confirmer
            </button>
            {checkedSongs.length === 0 && (
              <p className="text-red-600 text-xs">
                Ajoutez au moins une chanson pour confirmer
              </p>
            )}
          </div>
        </div>
      )}
      {confirm && (
        <div className="max-w-md px-4 mx-auto mt-12 flex flex-col gap-y-2">
          <h1 className="text-3xl text-center">Récapitulatif</h1>
          <p className="text-center mt-4">
            Veuillez compléter le formulaire afin de faire pavenir votre choix
            de chanson à notre DJ.
          </p>
          <h2 className="text-xl font-bold">
            {checkedSongs.length > 1
              ? "Chansons choisies :"
              : "Chanson choisie :"}
          </h2>{" "}
          <ul>
            {checkedSongs.map((song) => {
              return (
                <li key={song.id} className="">
                  <div className="flex mb-2 gap-2 text-xs sm:text-base">
                    <p>- {song.titre}</p>
                    <p className="font-bold">{song.chanteur}</p>
                  </div>
                </li>
              );
            })}
          </ul>
          <form>
            <div className="mb-4">
              <label
                className="block text-gray-700 font-bold mb-2"
                htmlFor="firstName"
              >
                Prénom*
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="firstName"
                type="text"
                placeholder="Prénom"
                name="firstName"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
              />
            </div>
            <div className="mb-6">
              <label
                className="block text-gray-700 font-bold mb-2"
                htmlFor="lastName"
              >
                Nom
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="lastName"
                type="text"
                placeholder="Nom"
                name="lastName"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
            <div className="mb-6">
              <label
                className="block text-gray-700 font-bold mb-2"
                htmlFor="details"
              >
                Détails
              </label>
              <textarea
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-32"
                id="details"
                type="text"
                placeholder="Détails / Précisions"
                name="details"
                value={details}
                onChange={(e) => setDetails(e.target.value)}
              />
            </div>
            <div className="flex w-fit m-auto gap-2">
              <button
                type="submit"
                onClick={handlePHP}
                className="bg-[#599959] hover:bg-green-700 text-white font-bold py-2 px-4 rounded flex m-auto"
              >
                Envoyer
              </button>
              <button
                className="bg-white text-[#599959] border-[#599959] border hover:bg-green-700 hover:text-white font-bold py-2 px-4 rounded flex m-auto"
                onClick={toggleConfirm}
              >
                Précédent
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
}

export default App;
